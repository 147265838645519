import { BudgetGroupResponse, BudgetGroupsResponse } from 'src/models/api/budgetGroupResponse'

const budgetGroupDecimalProperties = ['target_profit_rate'] as const
type BudgetGroupDecimalProperties = typeof budgetGroupDecimalProperties[number]

export type BudgetGroup = Omit<BudgetGroupResponse, BudgetGroupDecimalProperties> & {
  [k in BudgetGroupDecimalProperties]: number
}

export function budgetGroupResponseToBudgetGroup(budgetGroupResponse: BudgetGroupResponse): BudgetGroup {
  return Object.entries(budgetGroupResponse).reduce((acc: Partial<BudgetGroup>, [k, v]) => {
    if (budgetGroupDecimalProperties.includes(k as any)) {
      acc[k as BudgetGroupDecimalProperties] = parseFloat(v)
    } else {
      acc[k as keyof BudgetGroup] = v
    }
    return acc
  }, {}) as BudgetGroup
}

export function budgetGroupsResponseToBudgetGroups(budgetGroupsResponse: BudgetGroupsResponse): BudgetGroup[] {
  return budgetGroupsResponse.map<BudgetGroup>(e => budgetGroupResponseToBudgetGroup(e))
}
